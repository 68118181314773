import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../../containers/page';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import BlogArticle from '../../components/articles/blogArticle';
import rightArrow from '../../assets/svg/right-arrow.svg';
import user from '../../assets/svg/user.svg';
import penIcon from '../../assets/svg/orders-pen.svg';
import trashIcon from '../../assets/svg/orders-trash.svg';
import { API_ENDPOINT } from '../../constants';


class NewsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            items: []
        };
    }

    componentDidMount() {

        window.scrollTo(0, 0);


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props[0].match, this.props[0].location.pathname).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state));
                })
            })
        }

        fetch(`${API_ENDPOINT}/blog/all`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
        }).then(res => res.json()).then((result) => {
            this.setState({
                items: result
            })
        })

    }


    render() {

        return (
            <div className="account-wrap">
                <div className="into-wrap">
                    <Container>
                        <Row>
                            <Col lg="4" className="user-info">
                                <Isvg src={user} />
                                <div>
                                    <h1>Moj nalog</h1>
                                    <p className="email">{this.props.uData && this.props.uData.email}</p>
                                    <button onClick={() => this.props.signOut()}>Izloguj se</button>
                                </div>
                            </Col>
                            <Col lg={{ size: 8 }} className="user-nav">
                                <Link to='/account/orders'><button >MOJE NARUDŽBE</button></Link>
                                <Link to='/account/edit'><button>PODEŠAVANJA</button></Link>
                                <Link to='/account/reviews'><button >OCJENE</button></Link>
                                {this.props.uData && this.props.uData.permissions && this.props.uData.permissions.indexOf('*') !== -1 ? <Link to='/account/categories'><button className="active">ADMINISTRACIJA</button></Link> : null}

                            </Col>
                        </Row>

                    </Container>
                </div>


                <section >
                    <Container>
                        <Row>
                            <Col lg="12" >
                                <div className="admin-form">
                                    <ul className="tabs">
                                        <li >
                                            <Link to='/account/categories'>Kategorije</Link>
                                        </li>
                                        <li>
                                            <Link to='/account/users'>Korisnici</Link>
                                        </li>
                                        <li className="active">
                                            <Link to='/account/news'>Novosti</Link>
                                        </li>
                                        <li>
                                            <Link to='/account/pages'>Stranice</Link>
                                        </li>

                                        <li>
                                            <Link to='/'>Statistike</Link>
                                        </li>
                                        <li>
                                            <Link to='/account/create-store'>OTVORI SHOP</Link>
                                        </li>
                                    </ul>

                                    <div className="form-wrapper">
                                        <div className="top">
                                            <h3>Novosti</h3>
                                            <Link to='/account/news/new'>
                                                <button className="button">Dodaj novost</button>

                                            </Link>

                                        </div>
                                        <div className="table">
                                            <div>
                                                <table>
                                                    <tr>
                                                        <th></th>
                                                        <th>Naslov</th>
                                                        <th>Akcije</th>
                                                    </tr>

                                                    {
                                                        this.state.items && this.state.items.length && this.state.items.map((item, idx) => {
                                                            return (
                                                                <tr>
                                                                    <td className="image-col"><img src={item.image} /></td>
                                                                    <td>{item.title}</td>
                                                                    <td>
                                                                        <Link to={`/account/news/${item._id}`}><button><Isvg src={penIcon} /></button></Link>
                                                                        <Link to='/'><button><Isvg src={trashIcon} /></button></Link>
                                                                    </td>

                                                                </tr>

                                                            )
                                                        })
                                                    }
                                                </table>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </Col>
                        </Row>

                    </Container>

                </section>




            </div>
        );
    }
}

export default Page(NewsPage);